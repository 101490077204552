import React, { useContext } from 'react';
import { useStyles } from './PolicyPaymentStyles';
import { StepContext } from '../../../contexts/StepContext';
import { Typography, Divider } from '..';
import clsx from 'clsx';
import { convertDateToDisplayFormat } from '../../../utils/dateFormattingUtils';

interface IPolicyPayment {
  price: number | null;
  policyStartDate: any;
  policyEndDate?: any;
}

export const PolicyPayment: React.FC<IPolicyPayment> = ({ price }: IPolicyPayment): JSX.Element => {
  const classes = useStyles();

  const { data } = useContext(StepContext);

  const coverTypeLong = data.coverType.includes("Annual") ? "annual multi-trip" : "single trip";

  const formattedPolicyStartDate = data.policyStartDate
    ? convertDateToDisplayFormat(data.policyStartDate.toString())
    : '';

  const formattedPolicyEndDate = data.policyEndDate
    ? convertDateToDisplayFormat(data.policyEndDate.toString())
    : '';

  return (
    <div className={classes.policyPaymentContainer}>
      <div className={classes.policyPaymentContainerBg}>
        <Typography variant="h3" className={classes.policyPaymentHeading}>
          Payment for your {coverTypeLong} cover.
        </Typography>
        <div className={classes.valueContainer}>
          <Typography variant="body1">Total cost:</Typography>
          <Typography variant="body1" className={classes.values}>
            £{price ? price.toFixed(2) : ''}
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.valueContainer}>
          <Typography variant="body1">
            Start date:
          </Typography>
          <Typography variant="body1" className={classes.values}>
            {formattedPolicyStartDate}
          </Typography>
        </div>
        <div className={clsx(classes.valueContainer, 'pb2')}>
          <Typography variant="body1">
            End date:
          </Typography>
          <Typography variant="body1" className={classes.values}>
            {formattedPolicyEndDate}
          </Typography>
        </div>
        <div className={classes.noticeContainer}>
          <Typography variant="body2" className={classes.notice}>
            Includes Insurance Premium Tax where applicable.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PolicyPayment;
