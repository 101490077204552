import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const GlobalCss = withStyles((theme: Theme) => ({
  '@global': {
    /* Helper */
    '.fw-500': {
      fontWeight: 500,
    },
    '.textCenter': {
      textAlign: 'center',
    },

    /* Margin */
    '.mt2': {
      marginTop: '2rem',
    },
    '.mt3': {
      marginTop: '3rem',
    },
    '.mt4': {
      marginTop: '4rem',
    },
    '.mb05': {
      marginBottom: '0.5rem',
    },
    '.mb1': {
      marginBottom: '1rem',
    },
    '.mb2': {
      marginBottom: '2rem',
    },
    '.mb3': {
      marginBottom: '3rem',
    },
    '.my1': {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    '.my2': {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    '.my3': {
      marginTop: '3rem',
      marginBottom: '3rem',
    },

    /* Padding */
    '.pl1': {
      paddingLeft: '1rem',
    },
    '.pt1': {
      paddingTop: '1rem',
    },
    '.pt2': {
      paddingTop: '2rem',
    },
    '.pt3': {
      paddingTop: '3rem',
    },
    '.pb05': {
      paddingBottom: '.5rem'
    },
    '.pb1': {
      paddingBottom: '1rem',
    },
    '.pb2': {
      paddingBottom: '2rem',
    },
    '.pb3': {
      paddingBottom: '3rem'
    },
    '.py1': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    '.py2': {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },

    '.lead1': theme.leads.lead1,
    '.lead2': theme.leads.lead2,
    '.overline1': theme.overlines.overline1,
    '.overline2': theme.overlines.overline2,

    '.inputLabel': {
      ...theme.form.labels.inputField.label,
    },
    '.fieldError': {
      ...theme.form.labels.selectFieldSmall.hint,
      color: theme.palette.utility.redDark,
      fontWeight: 500,
      lineHeight: '2.25rem',
    },
    '.fieldErrorLong': {
      ...theme.form.labels.selectFieldSmall.hint,
      color: theme.palette.utility.redDark,
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    '.anchorLink': {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
  },
}))(() => null);

export default GlobalCss;
