import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    billingAddressContainer: {
      border: `2px solid ${theme.palette.dark[500]}`,
      maxWidth: '40rem',
      marginBottom: '3rem',
      '& h3': {
        marginBottom: '2rem',
      },
    },
    billingAddressGrid: {
      padding: '1rem 1.75rem',
    },
    bottomHighlight: {
      padding: '0rem 1.25rem',
      backgroundColor: theme.palette.light[100],
    },
    postcodeLabel: {
      ...theme.form.labels.inputField.label,
      color: theme.palette.dark[800],
      marginBottom: '1rem'
    },
    lookupContainer: {
      display: 'flex',
      '@media (max-width: 500px)': {
        flexDirection: 'column'
      },
    },
    lookupBtn: {
      marginLeft: '2rem',
      maxWidth: '11rem',
      '@media (max-width: 500px)': {
        marginTop: '1rem',
        marginLeft: '0rem',
      },
    },
    minWidth14: {
      minWidth: '14rem',
    },
  }),
  { index: 1 },
);
