import React from 'react';
import { useStyles } from './PaymentDetailsStyles';
import { Box, ButtonBase, Grid, InputAdornment, InputLabel, SvgIcon } from '@material-ui/core';
import { TextField, Typography, DatePicker } from '../../atoms';
import { startOfMonth } from 'date-fns';
import visa from '../../../assets/img/cards/cc-visa.svg';
import americanExpress from '../../../assets/img/cards/cc-amex.svg';
import mastercard from '../../../assets/img/cards/cc-mastercard.svg';
import { ReactComponent as creditCardIcon } from '../../../assets/img/icons/adornment.svg';

interface IPaymentDetails {
  cardholdersName: string | number;
  cardNumber: string | number;
  expiryDate: Date | null;
  cardSecurityNumber: string | number;
  getCardType: () => string | null;
  AMERICAN_EXPRESS_TYPE_VALUE: string;
  control: any;
  errors: any;
}

export const PaymentDetails: React.FC<IPaymentDetails> = ({
  cardholdersName,
  cardNumber,
  expiryDate,
  cardSecurityNumber,
  getCardType,
  AMERICAN_EXPRESS_TYPE_VALUE,
  control,
  errors,
}: IPaymentDetails): JSX.Element => {
  const minExpiryDate = startOfMonth(new Date());
  const cardSecurityNumberMaxLength = getCardType() === AMERICAN_EXPRESS_TYPE_VALUE ? 4 : 3;
  const classes = useStyles();

  // Prevent onKeyPress form submission
  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.key === 'Enter' && e.preventDefault();
  };

  const handleOnKeyPressValidate = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    event.key === 'Enter' && event.preventDefault();
    if (event.key == 'Delete' || event.key == 'Backspace') {
      // allow deletes - Firefox issue
    } else {
      const pattern = /[0-9\s]/;
      const inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }

      if ((event.target as HTMLInputElement).value.length > 15) {
        // too many characters
        event.preventDefault();
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box mb="3rem">
            <Box className={classes.nameOnCardContainer}>
              <InputLabel className="inputLabel pb1" htmlFor="cardholdersName">
                Name on card
              </InputLabel>
              <Box className={classes.cardContainer}>
                <img alt="Visa card" className={classes.visaImage} src={visa} />
                <img alt="American Express card" className={classes.americanExpressImage} src={americanExpress} />
                <img alt="Mastercard" className={classes.mastercardImage} src={mastercard} />
              </Box>
            </Box>
            <TextField
              id="cardholdersName"
              className={classes.cardholdersNameField}
              name="cardholdersName"
              defaultValue={cardholdersName}
              control={control}
              fullWidth={true}
              onKeyPress={handleOnKeyPress}
              autoComplete="off"
            />
            <Typography className="fieldError">{errors.cardholdersName?.message}</Typography>
          </Box>
          <Box mb="3rem">
            <InputLabel className="inputLabel pb1" htmlFor="cardNumber">
              Card number
            </InputLabel>
            <TextField
              id="cardNumber"
              className={classes.cardNumberField}
              name="cardNumber"
              defaultValue={cardNumber}
              control={control}
              fullWidth={true}
              onKeyPress={handleOnKeyPressValidate}
              autoComplete="off"
            />
            <Typography className="fieldError">{errors.cardNumber?.message}</Typography>
          </Box>
          <Box mb="3rem">
            <InputLabel className="inputLabel pb1" htmlFor="expiryDate">
              Expiry date
            </InputLabel>
            <DatePicker
              id="expiryDate"
              className={classes.expiryDateField}
              name="expiryDate"
              control={control}
              defaultValue={expiryDate}
              placeholder="MM / YY"
              format="MM/yy"
              openTo="month"
              views={['month', 'year']}
              minDate={minExpiryDate}
              onKeyPress={handleOnKeyPress}
              inputAdornmentProps={{
                className: classes.expiryAdornment,
              }}
            />
            <Typography className="fieldError">{errors.expiryDate?.message}</Typography>
          </Box>

          <Box mb="1rem">
            <InputLabel className="inputLabel pb1" htmlFor="cardSecurityNumber">
              Security number
            </InputLabel>
            <TextField
              id="cardSecurityNumber"
              className={classes.cardSecurityNumberField}
              name="cardSecurityNumber"
              autoComplete="off"
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{
                    maxHeight: 'none',
                    marginRight: '1rem',
                  }}
                >
                  <ButtonBase>
                    <SvgIcon component={creditCardIcon} className={classes.creditCardAdornment} />
                  </ButtonBase>
                </InputAdornment>
              }
              defaultValue={cardSecurityNumber}
              control={control}
              fullWidth={true}
              inputProps={{ maxLength: cardSecurityNumberMaxLength }}
              placeholder={cardSecurityNumberMaxLength === 4 ? '0000' : '000'}
              onKeyPress={handleOnKeyPressValidate}
            />
            <Typography className="fieldError">{errors.cardSecurityNumber?.message}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentDetails;
