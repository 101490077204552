import React, { useEffect, useContext, useState } from 'react';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './CoverDetailsStyles';
import { Grid, FormControl, Box } from '@material-ui/core';
import { StepContext, IStepData, steps, Step } from '../../contexts/StepContext';
import { format } from 'date-fns';
import { VehicleLookUp } from '../../components/molecules/VehicleLookup/VehicleLookUp';
import {
  DocumentTitle,
  Typography,
  ButtonPrimary,
  RadioButtonGroup,
  Divider,
  Checkbox,
  AnchorAccordion,
  Notice,
} from '../../components/atoms';
import {
  comparisonWebsiteSources,
  isValidComparisonWebsiteSource,
  getCoverPricingData,
  calculateEndDateForAnnual,
} from '../../utils/coverDetailsUtils';
import { formatDate, dateIsValid } from '../../utils/dateFormattingUtils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import * as yup from 'yup';
import axios from 'axios';
import CoverDetailsSchema from './CoverDetailsSchema';
import { buildInternalProductNameString } from '../../utils/optionNameUtils';
import { addDays, startOfDay } from 'date-fns/esm';
import uuid from 'uuid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { subYears } from 'date-fns';

interface IParams {
  id: string;
  hash: string;
}
export const CoverDetails: React.FC = () => {
  const { activeStep, updateActiveStep, loading, updateLoading, updateData, data } = useContext(StepContext);
  const { id, hash } = useParams<IParams>();
  const [newUserQuoteId, setNewUserQuoteId] = useState<string>('');
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null | undefined | string>(
    data.policyStartDate !== undefined ? data.policyStartDate : null,
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null | undefined | string>(
    data.policyEndDate !== undefined ? data.policyEndDate : null,
  );

  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const redirectNewUserToCoverDetails = () => {
    if (location.pathname === '/' || location.pathname === '/european-cover') {
      history.push(`/coverOptions/${uuid.v4()}`);
    }
  };

  useEffect(() => {
    updateActiveStep(1);

    id ? getDataFromThirdParty() : redirectNewUserToCoverDetails();
  }, [id]);

  const getQuoteData = async () => {
    if (hash) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_QUOTE_GET_ENDPOINT}/${id}/${hash}`
        );
        return response.data.data.Items[0];
      } catch (error: any) {
          return error.response?.data?.message;
      }
    }
  };

  const { CaravanTowCheck, AccordionBody, TripType } = CoverDetailsSchema;

  const getOptionsData = async (quoteData) => {
    try {
      const { data: options } = await axios.get(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_OPTIONS_ENDPOINT}`,
      );
      const stateDataObjectInternal = {
        ...quoteData,
        options: options.Items,
        tripType: 'Continental',
        baseOption: 'Continental',
        caravanTowCheck: '',
        policyStartDate: null,
        policyEndDate: null,
        dateOfBirth: null,
        vehicleLookUp: {
          vehicleMake: '',
          vehicleModel: '',
          vehicleYear: '',
          registrationNumber: '',
        },
      };
      return stateDataObjectInternal;
    } catch (err) {
      window.location.replace('https://www.greenflag.com');
      // consideration of error page for here rather than just a redirect.
    }
  };

  const getCoverDetailsData = async (quoteData) => {
    // Checks that the referring comparison site exists.
    if (!isValidComparisonWebsiteSource(comparisonWebsiteSources, quoteData.source))
      window.location.href = 'https://www.greenflag.com';

    try {
      const { data: options } = await axios.get(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_OPTIONS_ENDPOINT}`,
      );

      const { baseOption, additionalOptions } = buildInternalProductNameString(quoteData.productName);

      const stateDataObjectInternal = {
        ...quoteData,
        baseOption,
        tripType: baseOption,
        caravanTowCheck: additionalOptions.includes('Towing') ? 'true' : 'false',
        options: options.Items,
        vehicleLookUp: {
          vehicleMake: quoteData.vehicleMake,
          vehicleModel: quoteData.vehicleModel,
          vehicleYear: new Date().getFullYear() - quoteData.vehicleAge,
          registrationNumber: quoteData.registrationNumber,
        },
      };
      return stateDataObjectInternal;
    } catch (err) {
      window.location.replace('https://www.greenflag.com');
      // consideration of error page for here rather than just a redirect.
    }
  };

  const getDataFromThirdParty = async () => {
    if (data.quote === '' || data.quote !== id) {
      let quoteDataReactFormat;
      let stateDataObject;
      const quoteData = await getQuoteData();

      try {
        quoteDataReactFormat = {
          source: quoteData.Source,
          title: quoteData.Title,
          firstName: quoteData.FirstName,
          lastName: quoteData.LastName,
          dateOfBirth: quoteData.DateOfBirth,
          emailAddress: quoteData.EmailAddress,
          phoneNumber: quoteData.PhoneNumber,
          postcode: quoteData.Postcode,
          addressLine1: quoteData.AddressLine1,
          addressLine2: quoteData.AddressLine2,
          addressLine3: quoteData.AddressLine3,
          addressLine4: '',
          vehicleType: quoteData.VehicleType,
          registrationNumber: quoteData.RegistrationNumber.toUpperCase(),
          vehicleMake: quoteData.VehicleMake,
          vehicleModel: quoteData.VehicleModel,
          vehicleAge: quoteData.VehicleAge,
          productName: quoteData.ProductName,
          personalCover: quoteData.PersonalCover,
          coverPrice: quoteData.CoverPrice,
          policyStartDate: quoteData.PolicyStartDate,
          policyEndDate: quoteData.PolicyEndDate,
          partnerTitle: quoteData.PartnerTitle,
          partnerInitial: quoteData.PartnerInitial,
          partnerSurname: quoteData.PartnerSurname,
          quote: quoteData.quote_id,
          quoteHash: hash,
        };

        stateDataObject = await getCoverDetailsData(quoteDataReactFormat);
        setSelectedStartDate(quoteData.PolicyStartDate);
        setSelectedEndDate(quoteData.PolicyEndDate);
      } catch {
        quoteDataReactFormat = {
          source: quoteData === 'Quote has expired' ? 'EXPIRED' : 'DIRECT',
          quote: id
        };
        stateDataObject = await getOptionsData(quoteDataReactFormat);

        if (quoteData === 'Quote has expired')
        {
          history.replace('/timeout');
        }
      }

      updateData(stateDataObject);
      reset(stateDataObject);
    }
    updateLoading(false);
  };

  //as per legacy, today's date is unpickable (+1 day)
  const minStartDate = addDays(startOfDay(new Date()), 1);
  const minStartDateYup =
    data.policyStartDate !== undefined && data.policyStartDate != new Date()
      ? addDays(startOfDay(new Date()), 1)
      : addDays(startOfDay(new Date()), 1);
  const maxStartDate = addDays(startOfDay(new Date()), 90);
  const maximumNumberOfDaysYup = addDays(
    new Date(selectedStartDate ? format(new Date(selectedStartDate), 'yyyy/MM/dd') : new Date()),
    90,
  );
  
  const minVehicleYear: Date = subYears(new Date(), 17);

  const schema = yup.object().shape({
    tripType: yup
      .string()
      .required('Please select an option')
      .oneOf(['Continental', 'Annual'], 'Please select an option'),
    policyStartDate: yup
      .date()
      .typeError('Please enter a start date')
      .required('Please enter a start date')
      .min(minStartDateYup, "Policy start date cannot be in the past or today's date")
      .max(maxStartDate, 'Policy start date cannot be more than 90 days from today'),
    policyEndDate: yup.date().when('tripType', {
      is: 'Continental',
      then: yup
        .date()
        .required('Please enter an end date')
        .typeError('Please enter an end date')
        .min(yup.ref('policyStartDate'), 'End date cannot be before start date')
        .max(maximumNumberOfDaysYup, 'Policy end date cannot be more than 90 days after start date'),
    }),
    caravanTowCheck: yup
      .string()
      .required('Please make a selection')
      .oneOf(['true', 'false'], 'Please tell us if you are towing a caravan or trailer'),
    vehicleLookUp: yup.object().shape({
      registrationNumber: yup.string().required('Please enter your registration'),
      hasItBeenClicked: yup
        .boolean()
        .oneOf([true], 'Let us know your registration number')
        .required('Let us know your registration number'),
      //The following is extra validation in case of a direct quote with a vehicle older than 16 years.
      vehicleYear: yup
        .date()
        .min(minVehicleYear, "Sorry, but we're not able to cover vehicles that are over 16 years old")
        .max(new Date(), 'The year of manufacture must be the current year or lower'),
    }),
    vehicleCriteriaIsValid: yup.bool().when('caravanTowCheck', {
      is: 'true',
      then: yup
        .bool()
        .oneOf([true], 'You must read and accept the towed caravan and trailer requirements to continue')
        .required('You must read and accept the towed caravan and trailer requirements to continue'),
    }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      vehicleCriteriaIsValid: false,
      // baseOption,
      tripType: data.baseOption.includes('Annual') ? 'Annual' : 'Continental',
      //TODO: when journey is direct, populate additionalOption when options endpoint gets called.
      caravanTowCheck:
        data.additionalOption !== undefined ? (data.additionalOption.includes('Towing') ? 'true' : 'false') : '',
      policyStartDate: data.policyStartDate !== undefined ? data.policyStartDate : null,
      policyEndDate: data.policyEndDate !== undefined ? data.policyEndDate : null,
      vehicleLookUp: {
        vehicleMake: data.vehicleMake,
        vehicleModel: data.vehicleModel,
        vehicleYear: new Date().getFullYear() - data.vehicleAge,
        registrationNumber: data.registrationNumber.toUpperCase(),
        hasItBeenClicked: false,
      },
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const caravanTowCheck = getValues('caravanTowCheck');
  let tripType = watch('tripType');
  let policyEndDateFromPicker = getValues('policyEndDate');
  let additionalOptionsForPriceUpdated = caravanTowCheck.includes('true') ? ['Towing'] : [];
  let updatedCoverPrice;

  const onChangeCaravanRadioButton = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.includes('true')) {
      additionalOptionsForPriceUpdated = ['Towing'];
    } else {
      additionalOptionsForPriceUpdated = [];
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    trigger('tripType');
    tripType = e.target.value;

    if (tripType.includes('Continental')) {
      policyEndDateFromPicker = null;
      setSelectedEndDate(null);
      setValue('policyEndDate', null);
    } else {
      policyEndDateFromPicker = calculateEndDateForAnnual(selectedStartDate);
    }

    setValue('tripType', tripType);
    setValue('baseOption', tripType);
    setValue('policyEndDate', policyEndDateFromPicker);
  };

  function onStartDateChange(startDate) {
    if (dateIsValid(startDate)) {
      setSelectedStartDate(format(new Date(startDate), 'yyyy/MM/dd'));
      setValue('policyStartDate', format(new Date(startDate), 'yyyy/MM/dd'));
    }
  }

  function onEndDateChange(endDate) {
    if (dateIsValid(endDate)) {
      setSelectedEndDate(format(new Date(endDate), 'yyyy/MM/dd'));
      setValue('policyEndDate', format(new Date(endDate), 'yyyy/MM/dd'));
    }
  }

  const maximumNumberDaysPicker = addDays(new Date(formatDate(selectedStartDate)), 90);

  function showPreDepartureWarning(passedDate) {
    const dateSelected = new Date(passedDate);
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + 7);

    if (dateSelected > newDate) {
      return false;
    } else {
      return true;
    }
  }

  const vlMake = watch('vehicleLookUp.vehicleMake');
  const vlModel = watch('vehicleLookUp.vehicleModel');
  const vlYear = watch('vehicleLookUp.vehicleYear');
  const vlRegistrationNumber = watch('vehicleLookUp.registrationNumber');

  const onSubmit = (stepData: IStepData) => {
    const policyStartDateFromPickerForSubmission = getValues('policyStartDate');
    const policyEndDateFromPickerForSubmission = getValues('policyEndDate');
    const startDate = new Date(formatDate(policyStartDateFromPickerForSubmission));

    const endDate = new Date(new Date(formatDate(policyEndDateFromPickerForSubmission)));
    const vehicleAgeFromVehicleYear = new Date().getFullYear() - vlYear;

    updatedCoverPrice = getCoverPricingData(
      data.options,
      tripType,
      additionalOptionsForPriceUpdated,
      vehicleAgeFromVehicleYear,
      formatDate(startDate),
      formatDate(endDate),
    );

    const caluclateEndDateOnSubmit = tripType.includes('Annual')
      ? format(new Date(calculateEndDateForAnnual(formatDate(policyStartDateFromPickerForSubmission))), 'yyyy/MM/dd')
      : format(new Date(formatDate(policyEndDateFromPickerForSubmission)), 'yyyy/MM/dd');

    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
      policyStartDate: format(new Date(formatDate(policyStartDateFromPickerForSubmission)), 'yyyy/MM/dd'),
      policyEndDate: caluclateEndDateOnSubmit,
      vehicleMake: vlMake,
      vehicleModel: vlModel,
      vehicleAge: new Date().getFullYear() - vlYear,
      registrationNumber: vlRegistrationNumber.toUpperCase(),
      coverType: tripType,
      additionalOption: caravanTowCheck.includes('true') ? ['Towing'] : [],
      coverPrice: updatedCoverPrice.coverPrice,
      quoteHash: hash,
    });
    history.push(steps[Step.PERSONAL].url);
  };

  if (loading) return <></>;

  return (
    <PageTemplate>
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Cover Details`} />
      <Controller control={control} name="coverType" defaultValue={data.coverType} render={() => <></>} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Typography className={classes.detailsCorrectHeading} variant="h1">
              Let&apos;s get this show on the road.
            </Typography>

            <Typography variant="h2">Do you need cover for one trip or multiple?</Typography>
            <Box className={classes.maxWidth30}>
              <AnchorAccordion
                heading={AccordionBody.heading}
                body={AccordionBody.body}
                expandedHeading={AccordionBody.expandedHeading}
              />
            </Box>

            <Box className={classes.maxWidth30}>
              <RadioButtonGroup
                control={control}
                name="tripType"
                watch={watch}
                defaultValue={tripType}
                data={TripType}
                onChange={onChange}
              />
              <Typography className="fieldError">{errors.tripType?.message}</Typography>
            </Box>

            <Box className="mt2">
              <Typography variant="h2">When do you need your cover to start?</Typography>
              <Typography className="pt1">You can get cover for one day, 90 days, or anything in between.</Typography>

              <Typography className={clsx(classes.fw700, classes.dateHeadings)}>Start Date</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="policyStartDate"
                  name="policyStartDate"
                  variant="inline"
                  format="dd/MM/yyyy"
                  autoOk={true}
                  disablePast={true}
                  error={false}
                  helperText={null}
                  className={clsx(classes.DateField, classes.customDatePicker, 'mb1')}
                  defaultValue={data.policyStartDate}
                  minDate={minStartDate}
                  placeholder="DD/MM/YYYY"
                  maxDate={maxStartDate}
                  value={selectedStartDate}
                  onChange={(value) => onStartDateChange(value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>

              {showPreDepartureWarning(selectedStartDate) == true && selectedStartDate && (
                <Notice heading="" className={classes.maxWidth18} message={"Pre departure cover doesn't apply."} />
              )}

              <Typography className="fieldError">{errors.policyStartDate?.message}</Typography>

              {tripType === 'Continental' && (
                <>
                  <Typography className={clsx(classes.fw700, classes.dateHeadings)}>End Date</Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      id="policyEndDate"
                      name="policyEndDate"
                      variant="inline"
                      format="dd/MM/yyyy"
                      autoOk={true}
                      disablePast
                      error={false}
                      helperText={null}
                      className={clsx(classes.DateField, classes.customDatePicker, 'mb1')}
                      defaultValue={data.policyEndDate}
                      placeholder="DD/MM/YYYY"
                      minDate={formatDate(selectedStartDate)}
                      maxDate={maximumNumberDaysPicker}
                      value={selectedEndDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onChange={(value) => onEndDateChange(value)}
                    />
                  </MuiPickersUtilsProvider>

                  <Typography className="fieldError">{errors.policyEndDate?.message}</Typography>
                </>
              )}
            </Box>

            <Box className="pt3">
              <Typography className="pb2" variant="h2">
                Your vehicle
              </Typography>
              <VehicleLookUp
                vehicleMake={vlMake}
                vehicleModel={vlModel}
                vehicleYear={vlYear}
                registrationNumber={vlRegistrationNumber}
                vlName="vehicleLookUp.registrationNumber"
                vlRegistrationNumber={vlRegistrationNumber}
                setValue={setValue}
                trigger={trigger}
                control={control}
              />
              {errors.vehicleLookUp && (
                <Typography className="fieldError">
                  {errors.vehicleLookUp?.registrationNumber
                    ? errors.vehicleLookUp?.registrationNumber.message
                    : errors.vehicleLookUp?.hasItBeenClicked
                      ? errors.vehicleLookUp?.hasItBeenClicked.message
                      : errors.vehicleLookUp?.vehicleYear
                        ? errors.vehicleLookUp?.vehicleYear.message
                        : ''}
                </Typography>
              )}
            </Box>

            <Box className="mt2">
              <Typography variant="h2">Will you be towing a caravan or trailer?</Typography>
              <Typography className={clsx(classes.fw700, "pt1")}>Please note, your towed caravan or trailer must:</Typography>
              <ul>
                <li className="mb05">
                  <Typography>Be a standard make</Typography>
                </li>
                <li className="mb05">
                  <Typography>Fit a standard 50mm tow ball</Typography>
                </li>
                <li className="mb05">
                  <Typography>Weigh no more than 3.5 tonnes (including any load carried)</Typography>
                </li>
                <li className="mb05">
                  <Typography>
                    Be no more than 3 metres tall, 2.55 meters wide, and 7 meters long (excluding any coupling device and
                    tow bar)
                  </Typography>
                </li>
                <li className="mb05">
                  <Typography>Carry a serviceable spare wheel and tyre</Typography>
                </li>
                <li className="mb05">
                  <Typography>Not weigh more when loaded than the kerb weight of the insured vehicle</Typography>
                </li>
              </ul>
            </Box>

            <Box className={classes.maxWidth30}>
              <RadioButtonGroup
                control={control}
                name="caravanTowCheck"
                data={CaravanTowCheck}
                defaultValue={caravanTowCheck}
                watch={watch}
                onChange={onChangeCaravanRadioButton}
              />
              <Typography className="fieldError">{errors.caravanTowCheck?.message}</Typography>
            </Box>

            {caravanTowCheck === 'true' && (
              <Box className="mt3">
                <FormControl>
                  <Checkbox
                    name="vehicleCriteriaIsValid"
                    label="I've read and accept the towing requirements above."
                    watch={watch}
                    control={control}
                    trigger={trigger}
                    error={errors.vehicleCriteriaIsValid ? true : false}
                    color="primary"
                  />
                </FormControl>
                <Typography className="fieldError">{errors.vehicleCriteriaIsValid?.message}</Typography>
              </Box>
            )}

            <Divider className={clsx(classes.mb3, 'mt2')}></Divider>

            <div className={classes.actionButton}>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </div>
          </form>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default CoverDetails;
