import React, { useContext, useEffect } from 'react';
import {
  StepChecker,
  DocumentTitle,
  Typography,
  LinkNavigation,
  Accordion,
  ButtonSecondary,
  Checkbox,
  Divider,
  ButtonPrimary,
} from '../../components/atoms';
import { Grid, Box, FormControl } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './ImportantInfoStyles';
import { StepContext, steps, Step, IStepData } from '../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import importantInfoSchema from './ImportantSchema';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as yup from 'yup';
import clsx from 'clsx';

export const ImportantInfo: React.FC = () => {
  const { activeStep, updateActiveStep, data, updateData } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    updateActiveStep(3);
  }, []);

  const schema = yup.object().shape({
    meetsVehicleCriteria: yup.bool().oneOf([true], 'Checkbox must be checked to proceed'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      meetsVehicleCriteria: false,
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
    });
    history.push(steps[Step.PAYMENT].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
    });
    history.push(steps[Step.PERSONAL].url);
  };

  const getDocument = (filePath: string) => window.open(filePath);

  const { rightToCancel, complaints, financialCompensation, informationUsage, englishLawApplies } = importantInfoSchema;

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Review`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <LinkNavigation onClick={handleBack}>Back</LinkNavigation>
            <Typography className="pt2" variant="h1">
              All the important stuff.
            </Typography>
            <Typography className="pt3" variant="h2">
              Important statements
            </Typography>
            <Typography className={clsx(classes.reponsiveWidth, 'pt2')}>
              Please make sure all the information you have told us is correct and your vehicle meets the criteria
              below. Your price is worked out using this information and if anything you’ve told us is wrong, you might
              not be covered.
            </Typography>
            <Typography className="py2">Your vehicle(s) must meet these criteria:</Typography>
            
            <Box className={clsx(classes.reponsiveWidth, classes.importantStatementsList)}>
              <Typography>
                <li className="pb05">
                  It&apos;s either a car, light van, motorhome or motorbike
                </li>
                <li className="pb05">
                  It&apos;s privately registered in the UK
                </li>
                <li className="pb05">
                  It can&apos;t have more people in it than the manufacturer recommends, or over nine in total.
                </li>
                <li className="pb05">
                  It can&apos;t weigh more than 3.5 metric tons (3,500kg) in total, including any load carried
                </li>
                <li className="pb05">
                  It can&apos;t be more than 7 metres long (apart from a tow bar or coupling device), 3 metres tall and 2.55 metres wide
                </li>
                <li className="pb05">
                  It&apos;s been serviced, looked after and used as recommended by the manufacturer
                </li>
                <li className="pb05">
                  It&apos;s up to date with its MOT, tax, and insurance
                </li>
                <li className="pb05">
                  It&apos;s never driven if it&apos;s in an unroadworthy state at the start of a journey.
                </li>
                <li className="pb2">
                  It&apos;s never used to carry things or people for money (for example, as a courier service or taxi)
                </li>
                For more details on the types of vehicle we cover, please <a 
                  href="#" 
                  className="anchorLink" 
                  onClick={() => getDocument('pdfs/European-Policy.pdf')}
                  rel="noreferrer">
                    download our policy booklet
                  </a>.
              </Typography>
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt2')}>
              <Accordion heading={rightToCancel.heading} body={rightToCancel.body} />
              <Accordion heading={complaints.heading} body={complaints.body} />
              <Accordion heading={financialCompensation.heading} body={financialCompensation.body} />
              <Accordion heading={informationUsage.heading} body={informationUsage.body} />
              <Accordion heading={englishLawApplies.heading} body={englishLawApplies.body} />
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt1')}>
              <Typography variant="h3">Insurance product information document (IPID)</Typography>
              <Typography className="py2">
                To help you decide if this policy gives cover that&apos;s right for you, here&apos;s a summary of the main things
                that it will and won&apos;t cover. It&apos;s not personalised to you and doesn&apos;t contain everything, but full
                details are available before you buy and will be confirmed in documents we&apos;ll send if you take a
                policy with us.
              </Typography>
              <ButtonSecondary
                onClick={() => getDocument('pdfs/European-IPID.pdf')}
                endIcon={<GetAppIcon fontSize="large" />}
              >
                Download PDF (3.2MB)
              </ButtonSecondary>
              <Typography className="pt2">
                This product meets the demands and needs of those who wish to ensure that they have assistance
                if their vehicle breaks down and they are not in the UK.
              </Typography>
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt2')}>
              <FormControl component="fieldset">
                <Checkbox
                  name="meetsVehicleCriteria"
                  label="I confirm my vehicle meets the criteria listed above. And I understand that if my car tax and MOT are not up to date at the time of breakdown, my claim will be invalid."
                  watch={watch}
                  control={control}
                  trigger={trigger}
                  error={errors.meetsVehicleCriteria ? true : false}
                  color="primary"
                />
              </FormControl>
              <Typography className="fieldError">{errors.meetsVehicleCriteria?.message}</Typography>
            </Box>

            <Divider className={clsx(classes.reponsiveWidth, 'divider mt4')}></Divider>

            <Box className={clsx(classes.actionButton, 'pt1')}>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </Box>
          </form>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default ImportantInfo;
